@import '_variables.scss';

.themeContainer {
  width: 100%;
  margin: 30px;
  display: flex;
  align-items: center;

  > div {
    margin: 10px;
    min-width: 300px;
  }

  > span {
    width: 50px;
    height: 50px;
  }

  @media screen and (max-width: $laptop-screen-min) {
    flex-direction: column;
    width: auto;

    > span {
      width: 100%;
      height: 30px;
    }
  }
}

.projectSettingsContainer {
  display: flex;
  flex-direction: column;
}

.clearIcon {
  opacity: 0.8;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
}
