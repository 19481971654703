@import '_variables.scss';
::-webkit-scrollbar {
  display: none;
}

#root {
  position: relative;
  min-height: 100vh;

  .content {
    overflow: hidden;
  }
}

@media only screen and (max-width: $default-screen-min) {
  #root {
    background-color: white;
  }
}
