@import '_variables.scss';

.projectSettings {
  display: flex;
  flex-direction: column;

  margin: 30px;

  @media screen and (max-width: $laptop-screen-min) {
    margin: 20px;
  }

  > * {
    margin: 20px 0px;

    > * {
      margin: 10px 0px;

      > * {
        margin-right: 10px;

        @media screen and (max-width: $laptop-screen-min) {
          margin-top: 20px;
          margin-right: 0px;
          min-width: 250px;
        }
      }
    }
  }
}
