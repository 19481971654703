@import '_variables.scss';

html,
body {
  padding: 0;
  margin: 0;

  font-family: Lato, sans-serif;
  background-color: rgb(250, 250, 250);
}

a:active,
a:focus,
input {
  outline: none 0;
}
