::-webkit-scrollbar {
  display: none;
}

#root {
  min-height: 100vh;
  position: relative;
}

#root .content {
  overflow: hidden;
}

@media only screen and (max-width: 1280px) {
  #root {
    background-color: #fff;
  }
}

html, body {
  background-color: #fafafa;
  margin: 0;
  padding: 0;
  font-family: Lato, sans-serif;
}

a:active, a:focus, input {
  outline: 0;
}

.filterContainer {
  width: 90%;
  height: 50%;
  z-index: 5;
  background: #fff;
  border-width: 1px;
  border-top-style: solid;
  border-top-color: #d52941;
  border-radius: 6px;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
}

.buttonGroup {
  text-align: end;
}

.buttonGroup > button {
  margin: 10px;
}

.alternatives {
  grid-template-columns: 1fr 1fr;
  padding: 0 15px 15px;
  font-size: 14px;
  display: grid;
}

.filterpageContainer {
  display: grid;
  position: relative;
}

.subtitle {
  margin: 10px;
  font-size: 18px;
}

.filterContainer {
  width: 90%;
  height: 50%;
  z-index: 5;
  background: #fff;
  border-width: 1px;
  border-top-style: solid;
  border-top-color: #d52941;
  border-radius: 6px;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
}

.buttonGroup {
  text-align: end;
}

.buttonGroup > button {
  margin: 10px;
}

.alternatives {
  grid-template-columns: 1fr 1fr;
  padding: 0 15px 15px;
  font-size: 14px;
  display: grid;
}

.grouppageContainer {
  display: grid;
  position: relative;
}

.subtitle {
  margin: 10px;
  font-size: 18px;
}

.themeContainer {
  width: 100%;
  align-items: center;
  margin: 30px;
  display: flex;
}

.themeContainer > div {
  min-width: 300px;
  margin: 10px;
}

.themeContainer > span {
  width: 50px;
  height: 50px;
}

@media screen and (max-width: 960px) {
  .themeContainer {
    width: auto;
    flex-direction: column;
  }

  .themeContainer > span {
    width: 100%;
    height: 30px;
  }
}

.projectSettingsContainer {
  flex-direction: column;
  display: flex;
}

.clearIcon {
  opacity: .8;
  cursor: pointer;
}

.clearIcon :hover {
  opacity: 1;
}

.projectSettings {
  flex-direction: column;
  margin: 30px;
  display: flex;
}

@media screen and (max-width: 960px) {
  .projectSettings {
    margin: 20px;
  }
}

.projectSettings > * {
  margin: 20px 0;
}

.projectSettings > * > * {
  margin: 10px 0;
}

.projectSettings > * > * > * {
  margin-right: 10px;
}

@media screen and (max-width: 960px) {
  .projectSettings > * > * > * {
    min-width: 250px;
    margin-top: 20px;
    margin-right: 0;
  }
}

.projectUpdateButtons {
  place-self: end;
  margin-top: 30px;
}

.indexContainer {
  flex-direction: column;
  margin-right: 40px;
  display: flex;
}

/*# sourceMappingURL=index.d7233e2e.css.map */
