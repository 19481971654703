.projectUpdateButtons {
  align-self: end;
  justify-self: end;
  margin-top: 30px;
}

.indexContainer {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
}
