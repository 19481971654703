.filterContainer {
  position: absolute;
  background: white;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 50%;
  z-index: 5;
  border-radius: 6px;
  padding: 20px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-top: solid #d52941;
  border-width: 1px;
}

.buttonGroup {
  text-align: end;

  > button {
    margin: 10px;
  }
}

.alternatives {
  display: grid;
  font-size: 14px;
  grid-template-columns: 1fr 1fr;
  padding: 15px;
  padding-top: 0px;
}

.filterpageContainer {
  display: grid;
  position: relative;
}

.subtitle {
  font-size: 18px;
  margin: 10px;
}
